<template>
  <div :class="isStar()" :style="sizeS(size, size)">
    <template v-if="!loading">
      <v-avatar :size="size">
        <v-img :src="user.picture" v-if="user.picture">
          <template v-slot:placeholder>
            <v-icon :size="iconSize" color="vl8b8b8b"
              >mdi-account-circle</v-icon
            >
          </template>
        </v-img>
        <v-row class="fill-height ma-0" align="center" justify="center" v-else>
          <v-icon class="BG" :size="iconSize" color="vl8b8b8b"
            >mdi-account-circle</v-icon
          >
        </v-row>
      </v-avatar>
    </template>
    <template v-else>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="red" />
      </v-row>
    </template>
  </div>
</template>

<script>
import style from "@/mixins/style";
export default {
  mixins: [style],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: [Number, String],
      default: 36
    },
    user: {
      type: Object,
      default: null
    }
  },
  computed: {
    iconSize() {
      return this.size * 1.22;
    }
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "alt-image.jpg";
    },
    isStar() {
      if (this.user.accountType == "celebrity")
        return {
          star: true,
          starS: this.size < 37,
          starL: this.size > 36
        };
    }
  }
};
</script>

<style scoped>
.star {
  background-position: center center;
}
.starS {
  background-image: url("~@/assets/stars35X35Profile.svg");
}
.starL {
  background-image: url("~@/assets/stars84X84ProfileNoMoment.svg");
}
</style>
