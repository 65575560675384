export default {
    methods: {
        sizeS(w = 0, h = 0) {
            return (
                "width:" + w + "px !important;" +
                "height:" + h + "px !important;"
            );
        },
        cursor() {
            return "cursor:pointer;"
        },
        extBC() {
            return (
                "border-color: " + this.$vuetify.theme.currentTheme.extBC + " !important;"
            );
        },
        showArrows() {
            return window.innerWidth > 768;
        }
    },
    computed: {
        innerWidth() {
            return window.innerWidth-30
        }
    }
};
